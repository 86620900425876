import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { PagesModule } from './pages/pages.module';
import { FormsModule } from '@angular/forms';

import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireAuthModule } from '@angular/fire/auth';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

//firebase config
const config = {
   apiKey: "AIzaSyC9iSYqfySPW8SH5xa5eEsaMiIwYeQLnJU",
   authDomain: "website-3da52.firebaseapp.com",
   databaseURL: "https://website-3da52.firebaseio.com",
   projectId: "website-3da52",
   storageBucket: "website-3da52.appspot.com",
   messagingSenderId: "744190476909",
   appId: "1:744190476909:web:f0c50dc9e786e566893564",
   measurementId: "G-636G9DJC18"
}


@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    PagesModule,
    AngularFireModule.initializeApp(config),
    AngularFirestoreModule, // firestore
    AngularFireAuthModule, // auth
    AngularFireStorageModule // storage
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
