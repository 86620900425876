 <!-- STRAT NAVBAR -->
 <nav class="navbar navbar-expand-lg fixed-top navbar-custom sticky sticky-dark" (window:scroll)="windowScroll()"
   id="navbar">
   <div class="container">
     <!-- LOGO -->
     <a class="navbar-brand logo text-uppercase" href="/">
       <img class="logo" src="assets/images/logo.PNG" alt=""/>  ReelDillProductions
     </a>
     <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse"
       aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation" (click)="toggleMenu()">
       <i class="mdi mdi-menu"></i>
     </button>
     <div class="collapse navbar-collapse" id="navbarCollapse">
       <div class="nav-button ml-auto">
         <ul class="nav navbar-nav navbar-right">
           <li>
             <button [ngx-scroll-to]="'contact'" type="button" class="btn btn-custom navbar-btn btn-rounded">Contact</button>
           </li>
         </ul>
       </div>
     </div>
   </div>
 </nav>
 <!-- END NAVBAR -->
 <div appScrollspy [spiedTags]="['SECTION']" (sectionChange)="onSectionChange($event)">
   <!--START HOME-->
   <section class="section bg-home height-100vh" id="home">
     <div class="bg-overlay"></div>
     <div class="display-table">
       <div class="display-table-cell">
         <div class="container">
           <div class="row">
             <div class="col-lg-8 offset-lg-2 text-white text-center">
               <h1 class="home-title">Prescribing Video Therapy to Your Business</h1>
               <div class="embed-responsive embed-responsive-16by9">
                <video controls loop autoplay oncanplay="this.play()" onloadedmetadata="this.muted = true">
                    <source src="assets/images/RDP_Video.mp4" type="video/mp4">
                </video>
               </div>
               <p class="padding-t-15 home-desc mx-auto">Imagine showing your ideal client what you do, why you do it, and what differentiates you from the competition in 2 minutes</p>

             </div>
           </div>
         </div>
       </div>
     </div>
   </section>
   <ng-template #content let-modal>
     <div class="modal-header">
       testtestestestest
       <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
         <span aria-hidden="true">&times;</span>
       </button>
     </div>
     <div class="modal-body">
       again test
       <youtube-player [videoId]="'G-1dZqi8G4E'" height="450" width="780">
       </youtube-player>
     </div>
   </ng-template>
   <!--END HOME-->


   <app-features></app-features>
   <app-contact></app-contact>
   <section class="section-sm bg-light">
     <div class="container">
       <div class="row">
         <div class="col-md-3">
           <div class="client-images my-3 my-md-0">
             <img src="assets/images/company2.jpg" alt="logo-img" class="mx-auto img-fluid d-block">
           </div>
         </div>

         <div class="col-md-3">
           <div class="client-images my-3 my-md-0">
             <img src="assets/images/company3.png" alt="logo-img" class="mx-auto img-fluid d-block">
           </div>
         </div>

         <div class="col-md-3 ">
           <div class="client-images my-3 my-md-0">
             <img src="assets/images/company4.png" alt="logo-img" class="mx-auto img-fluid d-block">
           </div>
         </div>

         <div class="col-md-3">
           <div class="client-images my-3 my-md-0">
             <img src="assets/images/company5.png" alt="logo-img" class="mx-auto img-fluid d-block">
           </div>
         </div>
       </div>
     </div>
   </section>
   <app-footer></app-footer>
 </div>
