<!--START FEATURES -->
<section class="section" id="features">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-5 order-2 order-lg-1">
        <div class="features-box mt-5 mt-lg-0">
          <h3>Show your ideal client what makes you different</h3>
          <p class="text-muted web-desc">We specialized in being able to quickly and effectively reach your target audience</p>
          <ul class="text-muted list-unstyled margin-t-30 features-item-list">
            <li class="">Use video to reach a wide range of potential clients.</li>
            <li class="">Build a sleek and professional look for your business</li>
          </ul>
        </div>
      </div>
      <div class="col-lg-7 order-1 order-lg-2">
        <div class="features-img mx-auto mr-lg-0">
          <img src="assets/images/growth-analytics.svg" alt="macbook image" class="img-fluid">
        </div>
      </div>
    </div>
  </div>
</section>
<!--END FEATURES-->
