   <!--START ABOUT-US-->
   <section class="section" id="about">
     <div class="container">
       <div class="row">
         <div class="col-lg-8 offset-lg-2">
           <div class="about-title mx-auto text-center">
             <h2 class="font-weight-light">A Digital web studio creating stunning &amp; Engaging online Experiences
             </h2>
             <p class="text-muted pt-4">Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula
               eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur
               ridiculus mus. Donec quam felis.</p>
           </div>
         </div>
       </div>
       <div class="row margin-t-50">
         <div class="col-lg-3 col-sm-6">
           <div class="team-box text-center">
             <div class="team-wrapper">
               <div class="team-member">
                 <img alt="" src="assets/images/team/img-1.jpg" class="img-fluid rounded">
               </div>
             </div>
             <h4 class="team-name">Frank Johnson</h4>
             <p class="text-uppercase team-designation">CEO</p>
           </div>
         </div>

         <div class="col-lg-3 col-sm-6">
           <div class="team-box text-center">
             <div class="team-wrapper">
               <div class="team-member">
                 <img alt="" src="assets/images/team/img-2.jpg" class="img-fluid rounded">
               </div>
             </div>
             <h4 class="team-name">Elaine Stclair</h4>
             <p class="text-uppercase team-designation">Designer</p>
           </div>
         </div>

         <div class="col-lg-3 col-sm-6">
           <div class="team-box text-center">
             <div class="team-wrapper">
               <div class="team-member">
                 <img alt="" src="assets/images/team/img-3.jpg" class="img-fluid rounded">
               </div>
             </div>
             <h4 class="team-name">Wanda Arthur</h4>
             <p class="text-uppercase team-designation">Developer</p>
           </div>
         </div>

         <div class="col-lg-3 col-sm-6">
           <div class="team-box text-center">
             <div class="team-wrapper">
               <div class="team-member">
                 <img alt="" src="assets/images/team/img-4.jpg" class="img-fluid rounded">
               </div>
             </div>
             <h4 class="team-name">Joshua Stemple</h4>
             <p class="text-uppercase team-designation">Manager</p>
           </div>
         </div>

       </div>
     </div>
   </section>
   <!--END ABOUT-US-->
