 <!--START PRICING-->
 <section class="section bg-light" id="pricing">
   <div class="container">
     <div class="row">
       <div class="col-lg-8 offset-lg-2">
         <h1 class="section-title text-center">Our Pricing</h1>
         <div class="section-title-border margin-t-20"></div>
         <p class="section-subtitle font-secondary text-muted text-center padding-t-30">Call to action pricing table is
           really crucial to your for your business website. Make your bids stand-out with amazing options.</p>
       </div>
     </div>
     <div class="row margin-t-50">
       <div class="col-lg-4">
         <div class="text-center pricing-box">
           <h4 class="text-uppercase">Economy</h4>
           <h1>$9.90</h1>
           <h6 class="text-uppercase text-muted">Billing Per Month</h6>
           <div class="plan-features margin-t-50">
             <p>Bandwidth: <b class="text-custom">1GB</b></p>
             <p>Onlinespace: <b class="text-custom">50MB</b></p>
             <p>Support: <b class="text-custom">No</b></p>
             <p><b class="text-custom">1</b> Domain</p>
             <p><b class="text-custom">No</b> Hidden Fees</p>
           </div>
           <a href="javascript: void(0);" class="btn btn-custom margin-t-30">Join Now</a>
         </div>
       </div>
       <div class="col-lg-4">
         <div class="text-center pricing-box bg-white price-active">
           <div class="ribbon-box"><span>Popular</span></div>
           <h4 class="text-uppercase">Deluxe</h4>
           <h1>$19.90</h1>
           <h6 class="text-uppercase text-muted">Billing Per Month</h6>
           <div class="plan-features margin-t-50">
             <p>Bandwidth: <b class="text-custom">10GB</b></p>
             <p>Onlinespace: <b class="text-custom">500MB</b></p>
             <p>Support: <b class="text-custom">Yes</b></p>
             <p><b class="text-custom">10</b> Domain</p>
             <p><b class="text-custom">No</b> Hidden Fees</p>
           </div>
           <a href="javascript: void(0);" class="btn btn-custom margin-t-30">Join Now</a>
         </div>
       </div>
       <div class="col-lg-4">
         <div class="text-center pricing-box">
           <h4 class="text-uppercase">Ultimate</h4>
           <h1>$29.90</h1>
           <h6 class="text-uppercase text-muted">Billing Per Month</h6>
           <div class="plan-features margin-t-50">
             <p>Bandwidth: <b class="text-custom">100GB</b></p>
             <p>Onlinespace: <b class="text-custom">2 GB</b></p>
             <p>Support: <b class="text-custom">Yes</b></p>
             <p><b class="text-custom">Unlimited</b> Domain</p>
             <p><b class="text-custom">No</b> Hidden Fees</p>
           </div>
           <a href="javascript: void(0);" class="btn btn-custom margin-t-30">Join Now</a>
         </div>
       </div>
     </div>
   </div>
 </section>
 <!--ENd PRICING-->

 <!--START TESTIMONIAL-->
 <section class="section" id="testi">
   <div class="container">
     <div class="row">
       <div class="col-lg-8 offset-lg-2">
         <h1 class="section-title text-center">What they've said</h1>
         <div class="section-title-border margin-t-20"></div>
         <p class="section-subtitle text-muted text-center font-secondary padding-t-30">The Big Oxmox advised her not to
           do so, because there were thousands of bad Commas, wild Question Marks and devious Semikoli.</p>
       </div>
     </div>
     <div class="row margin-t-50">
       <div class="col-lg-4">
         <div class="testimonial-box margin-t-30">
           <div class="testimonial-decs p-4">
             <div class="testi-icon">
               <i class="mdi mdi-format-quote-open display-2"></i>
             </div>
             <img src="assets/images/testimonials/user-1.jpg" alt=""
               class="img-fluid mx-auto d-block img-thumbnail rounded-circle mb-4">
             <div class="p-1">
               <h5 class="text-center text-uppercase mb-3">Dennis Williams - <span
                   class="text-muted text-capitalize">Charleston</span></h5>
               <p class="text-muted text-center mb-0">“I feel confident imposing change on myself. It's a lot more fun
                 progressing than looking back. That's why I need to throw curve balls.” </p>
             </div>
           </div>

         </div>
       </div>
       <div class="col-lg-4">
         <div class="testimonial-box margin-t-30">
           <div class="testimonial-decs p-4">
             <div class="testi-icon">
               <i class="mdi mdi-format-quote-open display-2"></i>
             </div>
             <img src="assets/images/testimonials/user-2.jpg" alt=""
               class="img-fluid mx-auto d-block img-thumbnail rounded-circle mb-4">
             <div class="p-1">
               <h5 class="text-center text-uppercase mb-3">Laurie Bell - <span
                   class="text-muted text-capitalize">Worcester</span></h5>
               <p class="text-muted text-center mb-0">“Our task must be to free ourselves by widening our circle of
                 compassion to embrace all living creatures and the whole of nature and its beauty.” </p>
             </div>
           </div>

         </div>
       </div>
       <div class="col-lg-4">
         <div class="testimonial-box margin-t-30">
           <div class="testimonial-decs p-4">
             <div class="testi-icon">
               <i class="mdi mdi-format-quote-open display-2"></i>
             </div>
             <img src="assets/images/testimonials/user-3.jpg" alt=""
               class="img-fluid mx-auto d-block img-thumbnail rounded-circle mb-4">
             <div class="p-1">
               <h5 class="text-center text-uppercase mb-3">Howard Kelley - <span
                   class="text-muted text-capitalize">Lynchburg</span></h5>
               <p class="text-muted text-center mb-0">“I've learned that people will forget what you said, people will
                 forget what you did, but people will never forget how you made them feel.” </p>
             </div>
           </div>

         </div>
       </div>
     </div>
   </div>
 </section>
 <!--END TESTIMONIAL-->

 <!--START GET STARTED-->
 <section class="section section-lg bg-get-start">
   <div class="bg-overlay"></div>
   <div class="container">
     <div class="row">
       <div class="col-lg-8 offset-lg-2 text-center">
         <h1 class="get-started-title text-white">Let's Get Started</h1>
         <div class="section-title-border margin-t-20 bg-white"></div>
         <p class="section-subtitle font-secondary text-white text-center padding-t-30">Far far away, behind the word
           mountains, far from the countries Vokalia and Consonantia, there live the blind texts. </p>
         <a href="javascript: void(0);" class="btn btn-bg-white waves-effect margin-t-20">Get Started <i
             class="mdi mdi-arrow-right"></i>
         </a>
       </div>
     </div>
   </div>
 </section>
 <!--END GET STARTED-->
