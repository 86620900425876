

<!--START FOOTER ALTER-->
<div class="footer-alt">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="float-left pull-none">
          <p class="copy-rights text-muted mb-3 mb-sm-0">2019 - {{year}} © ReelDil Productions</p>
        </div>
        <div class="float-right pull-none ">
          <ul class="list-inline social m-0">
            <li class="list-inline-item"><a href="https://www.facebook.com/ReelDillProductions/?modal=admin_todo_tour" class="social-icon"><i class="mdi mdi-facebook"></i></a></li>
            <li class="list-inline-item"><a href="https://www.instagram.com/reeldillproductions" class="social-icon"><i class="mdi mdi-instagram"></i></a></li>
            <li class="list-inline-item"><a href="https://www.linkedin.com/in/dillon-marcelin-2067629b/" class="social-icon"><i class="mdi mdi-linkedin"></i></a></li>
            <li class="list-inline-item"><a href="https://www.youtube.com/channel/UCoe5fLf6qx61sVd8Y6V2bGg" class="social-icon"><i class="mdi mdi-youtube"></i></a></li>
          </ul>
        </div>
        <div class="clearfix"></div>
      </div>
    </div>
  </div>
</div>
<!--START FOOTER ALTER-->
