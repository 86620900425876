// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
     apiKey: "AIzaSyC9iSYqfySPW8SH5xa5eEsaMiIwYeQLnJU",
     authDomain: "website-3da52.firebaseapp.com",
     databaseURL: "https://website-3da52.firebaseio.com",
     projectId: "website-3da52",
     storageBucket: "website-3da52.appspot.com",
     messagingSenderId: "744190476909",
     appId: "1:744190476909:web:f0c50dc9e786e566893564",
     measurementId: "G-636G9DJC18"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
