import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// TODO: rename Index3component to 'HOME'
import { Index3Component } from './index3/index3.component';
import { LoginComponent } from './login/login.component';
import { PasswordForgotComponent } from './password-forgot/password-forgot.component';
import { SignupComponent } from './signup/signup.component';
import { LinktreeComponent } from './linktree/linktree.component';


// TODO: remove references to other indexes; general cleanup
const routes: Routes = [
  {
    path: '',
    component: Index3Component
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'password_forgot',
    component: PasswordForgotComponent
  },
  {
    path: 'signup',
    component: SignupComponent
  },
  {
    path: 'links',
    component: LinktreeComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PagesRoutingModule { }
