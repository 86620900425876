import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FeaturesComponent } from './features/features.component';
import { PricingComponent } from './pricing/pricing.component';
import { BlogComponent } from './blog/blog.component';
import { ContactComponent } from './contact/contact.component';
import { AboutComponent } from './about/about.component';
import { ServicesComponent } from './services/services.component';
import { FooterComponent } from './footer/footer.component';
import { SwitcherComponent } from './switcher/switcher.component';
import { ScrollspyDirective } from './scrollspy.directive';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';



@NgModule({
  // tslint:disable-next-line: max-line-length
  declarations: [FeaturesComponent, PricingComponent, BlogComponent, ContactComponent, AboutComponent, ServicesComponent, FooterComponent, SwitcherComponent, ScrollspyDirective],
  imports: [
    AngularFireModule.initializeApp({
       apiKey: "AIzaSyC9iSYqfySPW8SH5xa5eEsaMiIwYeQLnJU",
       authDomain: "website-3da52.firebaseapp.com",
       databaseURL: "https://website-3da52.firebaseio.com",
       projectId: "website-3da52",
       storageBucket: "website-3da52.appspot.com",
       messagingSenderId: "744190476909",
       appId: "1:744190476909:web:f0c50dc9e786e566893564",
       measurementId: "G-636G9DJC18"
    }),
    AngularFirestoreModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule
  ],
  // tslint:disable-next-line: max-line-length
  exports: [FeaturesComponent, PricingComponent, BlogComponent, ContactComponent, AboutComponent, ServicesComponent, FooterComponent, SwitcherComponent, ScrollspyDirective]
})
export class SharedModule { }
