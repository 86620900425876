import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-linktree',
  templateUrl: './linktree.component.html',
  styleUrls: ['./linktree.component.css']
})
export class LinktreeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
