<body>
    <i onclick="mostrar(this)" class="fa fa-moon-o fa-2x night_mode"></i>

    <img id="userPhoto" src="assets/imagens/perfil.jpg" alt="User Photo">

    <span id="userName">Guilherme Soares Cota</span>

    <div id="links">
        <a class="link" href="https://guilhermesoares.tech" target="_blank" style="border-radius: 25px;"><i
                class="fa fa-globe" aria-hidden="true"></i> Meu site</a>
        <a class="link" href="https://github.com/guiscota" target="_blank" style="border-radius: 25px;"><i
                class="fa fa-github"></i> Github</a>
        <a class="link" href="https://looptech.com.br" target="_blank" style="border-radius: 25px;"><i
                class="fa fa-globe" aria-hidden="true"></i> Loop Tech</a>
    </div>

    <!-- Javascript -->
    <script src='https://cdnjs.cloudflare.com/ajax/libs/jquery/3.2.1/jquery.min.js'></script>
    <script src="index.js"></script>
</body>
