import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, FormControl, Validators } from '@angular/forms';
import { AngularFirestore } from '@angular/fire/firestore';

//modal setup
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {

  contactsCollection = this.db.collection('customers');


  name: string;
  email: string;
  number: number;
  website: string;
  experience: string;
  info: string;
  businessInfo: string;
  idealClient: string;
  competitors: string;
  transactions: string;
  finDec: string;
  biggestConcern: string;
  videoUses = [
    {
     Name: "Automation/Systems (Things you constantly repeat, Internal training, FAQ's, etc)",
     Checked: false
    },
    {
      Name: "Sales Videos (Content to use on sales pages, to send prospects, etc)",
      Checked: false
    },
    {
      Name: "Impact videos (Brand video, showcase the bigger picture, etc)",
      Checked: false
    }
  ]

  marketing = [
    {
      name: "SEO (Search Engine Optimization)",
      checked: false
    },
    {
      name: "Sales Videos (Content to use on sales pages, to send prospects, etc)",
      checked: false
    },
    {
      name: "PPC (Pay Per Click advertizing)",
      checked: false
    },
    {
      name: "Social Media Marketing",
      checked: false
    },
    {
      name: "Print or Direct Mail",
      checked: false
    },
    {
      name: "Radio",
      checked: false
    },
    {
      name: "TV",
      checked: false
    }
  ]

  expMap = ["Beginner", "Novice", "DIY", "Video Professional", "Video Professional II"]

  submitted = false;




  //these 3 functions should honestly just be just one
  checked(e){
    let item = this.videoUses[e]
    item.Checked = !item.Checked;
    console.log(item.Name, item.Checked);
  }

  checked2(i){
    let item = this.marketing[i]
    item.checked = !item.checked
    console.log(item.name, item.checked)
  }


  onFormSubmit(){
    const user = {
      name: this.name,
      email: this.email,
      number: this.number,
      website: this.website,
      info: this.info,
      videoUses: this.videoUses.filter(x => x.Checked == true).map(x => x.Name),
      idealClient: this.idealClient,
      competitors: this.competitors,
      transactions: this.transactions,
      experience: this.expMap[parseInt(this.experience) - 1],
      financialDecisions: this.finDec,
      marketing: this.marketing.filter(x => x.checked == true).map(x => x.name),
      biggestConcern: this.biggestConcern,
    }
    console.log("test", user);
    this.contactsCollection.add(user);
  }


  constructor(private db: AngularFirestore, private modalService: NgbModal) {}

  openWindowCustomClass(content) {
    this.modalService.open(content, { windowClass: 'dark-modal', size: 'lg' });
  }
  ngOnInit() {}

}
