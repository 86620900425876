    <!-- Modal -->
    <ng-template #content class="test">
      <div class="modal-container">
        <div class="modal-header">
        <h4 class="modal-title" id="modal-title"><strong>Thank you for your inquiry</strong></h4>
        </div>
        <div class="modal-body">
          <p>We will be in contact if we believe we are a good fit for your needs.
          </p>
        </div>
      </div>
    </ng-template>



    <!-- CONTACT FORM START-->
    <form (ngSubmit)="onFormSubmit()" class="section " id="contact">
      <div class="container">
        <div class="row">
          <div class="col-lg-8 offset-lg-2">
            <h1 class="section-title text-center">Discovery Form</h1>
            <div class="section-title-border margin-t-20"></div>
            <p class="section-subtitle text-muted text-center font-secondary padding-t-30">Fill out the discovery form below, if we find that it's a good fit, we'll be in contact with you</p>
          </div>
        </div>
        <div class="row">
          <div class="col-md">
            <div class="custom-form mt-4 pt-4">
              <div id="message"></div>
              <form method="post" action="php/contact.php" name="contact-form" id="contact-form">
                <div class="row">
                  <div class="col-lg-6">
                    <div class="form-group mt-2">
                      <input required #Name="ngModel" [(ngModel)]="name" name="name" id="name" type="text" class="form-control" [ngClass]="{'alert alert-danger': Name.touched && Name.invalid}" placeholder="Your name*">
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="form-group mt-2">
                      <input required #Email="ngModel" [(ngModel)]="email" name="email" id="email" type="email" class="form-control" [ngClass]="{'alert alert-danger': Email.touched && Email.invalid}" placeholder="Your email*">
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-6">
                    <div class="form-group mt-2">
                      <input required #Number="ngModel" [(ngModel)]="number" name="number" id="number" type="number" class="form-control" [ngClass]="{'alert alert-danger': Number.touched && Number.invalid}" placeholder="Your number*">
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="form-group mt-2">
                      <input [(ngModel)]="website" name="website" id="website" type="url" class="form-control" placeholder="Your website">
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-12">
                    <div class="form-group mt-2">
                      <textarea [(ngModel)]="info" name="comments" id="comments" rows="4" class="form-control"
                        placeholder="Tell me a little about your business..."></textarea>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <form class="col-lg-4 checkbox-select">
                    <h4 style="margin-top:20px;">What are you most interested in using video for?</h4>
                    <h5 style="margin-bottom:20px;">(select all that apply)</h5>
                    <div *ngFor="let item of videoUses; let i=index"class="form-check">
                      <input type="checkbox" (change)="checked(i)" class="form-check-input">
                      <label class="form-check-label" for="examplecheckboxs1">
                        {{item.Name}}
                      </label>
                    </div>
                  </form>
                </div>
                <div class="row">
                  <div class="col-lg-12">
                    <div class="form-group mt-2">
                      <textarea [(ngModel)]="idealClient" name="comments" id="comments" rows="4" class="form-control"
                        placeholder="Who is your ideal client?"></textarea>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-12">
                    <div class="form-group mt-2">
                      <textarea [(ngModel)]="competitors" name="comments" id="comments" rows="4" class="form-control"
                        placeholder="Who would you say are your top 3 competitors?"></textarea>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-12">
                    <div class="form-group mt-2">
                      <textarea required [(ngModel)]="transactions" name="comments" id="comments" rows="4" class="form-control"
                        placeholder="If a new customer does business with you , is this a one-time transaction or recurring? Are there additionalproducts or services you offer over a 12 month period?"></textarea>
                    </div>
                  </div>
                </div>
                <select [(ngModel)]="experience" name="experience" class="form-control mt-2" placeholder="Test">
                  <option [ngValue]="undefined" hidden>What's your experience with video?</option>
                  <option value="1">Beginner - Never been filmed on camera</option>
                  <option value="2">Novice - Appeared on camera in the past, but don't actively create videos</option>
                  <option value="3">DIY - Shoot my own videos regularly, ready for the Reel Dill!</option>
                  <option value="4">Video Professional - Appear on video regularly, create my own video content regularly</option>
                  <option value="5">Video Professional - Have a team that helps me create video content regularly</option>
                </select>
                <div class="row">
                  <div class="col-lg-12">
                    <div class="form-group mt-2">
                      <textarea required [(ngModel)]="finDec" name="comments" id="comments" rows="4" class="form-control"
                        placeholder="Is there anyone else involved in making financial decisions for the business?"></textarea>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-4 checkbox-select">
                    <h4 style="margin-top:20px;">Are you using any other forms of marketing?</h4>
                    <h5 style="margin-bottom:20px;">(select all that apply)</h5>
                    <div *ngFor="let item of marketing; let i=index" class="form-check">
                      <input (change)="checked2(i)" class="form-check-input" type="checkbox" name="examplecheckboxs" id="examplecheckboxs1" value="option1">
                      <label class="form-check-label" for="examplecheckboxs1">
                        {{item.name}}
                      </label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-12">
                    <div class="form-group mt-2">
                      <textarea [(ngModel)]="biggestConcern" name="comments" id="comments" rows="4" class="form-control"
                        placeholder="What is your biggest concern working with us to create videos for your business?"></textarea>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-12 text-left">
                    <input (click)="openWindowCustomClass(content); onFormSubmit()" type="submit" id="submit" name="send" class="submitBnt btn btn-custom" value="Submit">
                    <div id="simple-msg"></div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </form>
    <!-- CONTACT FORM END-->
